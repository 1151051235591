@charset 'UTF-8';
@import "/Users/nhduc/Website/vhost/ecoworks_real/src/scss/utility/_function.scss";
@import "/Users/nhduc/Website/vhost/ecoworks_real/src/scss/utility/_media-queries.scss";
@import "/Users/nhduc/Website/vhost/ecoworks_real/src/scss/utility/_mixin.scss";


.top {
  #wrap {
    overflow: hidden;
  }
  .mainvisual {
    position: relative;
    overflow: hidden;
    @include PC {
      height: 100vh;
    }
    @include SP {
      height: calc(100vh - 125px);
      &__img {
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    @include max-screen(374px) {
      height: auto;
    }
    &__img {
      @include PC {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }
    &__content {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-50%);
      @include PC {
        top: calc(50% + 22px);
      }
      @include SP {
        top: calc(50% - 18px);
      }
    }
    &__ttl {
      @include ship();
      color: #fff;
      transition-delay: 1s;
      @include font(46, 70, 0.15, 500);
      @include SP {
        @include font(26, 26);
        letter-spacing: 0.05em;
        margin-bottom: 10px;
      }
    }
    &__txt {
      transition-delay: 1.6s;
      @include eb();
      @include font(13, 33, 0.15, 500);
      color: #fff;
    }
    &__scroll {
      position: absolute;
      padding-top: 54px;
      @include PC {
        bottom: 23px;
        right: 37px;
      }
      @include screen(768px, 1200px) {
        bottom: 120px;
      }
      @include SP {
        bottom: 10px;
        right: 15px;
      }
      &::before {
        content: "";
        width: 1px;
        height: 48px;
        background: #fff;
        position: absolute;
        top: 0;
        right: 0;
      }
      .txt {
        color: #fff;
        @include eb();
        @include font(11, 33, 0.03, 500);
        @include SP {
          padding-right: 5px;
        }
      }
      .m-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 40px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        z-index: 999;
        transform: rotate(90deg) translateY(-40px) translateX(4px);
        @include PC {
          left: 1px;
        }
        @include SP {
          left: 6px;
        }
        .line {
          display: block;
          background: #fff;
          height: 3px;
          width: 50px;
          position: absolute;
          top: 48%;
          @include screen(768px, 1200px) {
            display: none;
          }
          @include max-screen(375px) {
            display: none;
          }
        }
        .line-ipad {
          display: block;
          background: #fff;
          height: 3px;
          width: 50px;
          position: absolute;
          top: 48%;
          display: none;
          @include screen(768px, 1200px) {
            top: 45%;
            display: block;
            width: 0;
            animation: mover 1.6s infinite;
            @keyframes mover {
              0% {
                left: 0;
                width: 0;
              }
              50% {
                left: 0;
                width: 50px;
              }
              100% {
                left: 100%;
                width: 0;
              }
            }
          }
          @include max-screen(375px) {
            top: 45%;
            display: block;
            width: 0;
            animation: mover 1.6s infinite;
            @keyframes mover {
              0% {
                left: 0;
                width: 0;
              }
              50% {
                left: 0;
                width: 50px;
              }
              100% {
                left: 100%;
                width: 0;
              }
            }
          }
        }
      }
    }
  }
  .top-news {
    margin: 0 auto;
    padding: 30px 0 0;
    @include SP {
      background: #fff;
      padding: 22px 0 0;
    }
    &__inner {
      max-width: 964px;
      width: 100%;
      position: relative;
      padding: 0 20px;
      margin: 0 auto;
      @include SP {
        padding: 23px 20px 0 24px;
      }
    }
    &__list {
      flex-direction: column;
      align-items: flex-start;
      @include PC {
        margin-top: 14px;
        height: 31px;
      }
      @include SP {
        height: 66px;
        margin-top: -2px;
      }
    }
    &__label {
      @include eb();
      @include font(14, 36, 0.1, 500);
      color: #1b1c1f;
      @include PC {
        position: relative;
      }
      @include SP {
        position: absolute;
        top: 16px;
        left: 24px;
        white-space: nowrap;
        padding: 0 20px 0 0;
      }
      &::after {
        content: "";
        position: absolute;
        width: 13px;
        height: 1px;
        background: #000;
        @include PC {
          bottom: 0;
          left: 0;
        }
        @include SP {
          right: 0;
          top: 52%;
        }
      }
    }
    &__wrap {
      @include SP {
        margin-bottom: 13px;
      }
    }
    .date {
      white-space: nowrap;
      @include eb();
      @include font(13, 28, 0.1, 400);
      @include SP {
        padding-left: 70px;
        padding-right: 7px;
        line-height: 1.5;
        font-size: 12px;
      }
    }
    .cat {
      max-width: 200px;
      @include PC {
        margin: 0 3px 0 12px;
      }
      @include SP {
        position: relative;
        display: inline-block;
        max-width: 150px;
      }
      span {
        @include font(12, 18px, 0.1, 500);
        border-radius: 11px;
        padding: 3px 11px;
        white-space: nowrap;
        display: block;
      }
    }
    .slash {
      color: #e0e0e0;
      @include SP {
        display: inline-block;
      }
    }
    .text {
      max-width: 676px;
      width: 100%;
      @include font(15, 20px, 0.03, 500);
      // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.634' height='6.684' viewBox='0 0 7.634 6.684'%3E%3Cpath id='arrow' d='M3.342,0,6.684,7.634l-3.342-3.6L0,7.634Z' transform='translate(7.634) rotate(90)' fill='%23061826'/%3E%3C/svg%3E%0A");
      // background-size: 9px;
      // background-repeat: no-repeat;
      // background-position: center right;
      position: relative;
      @include PC {
        padding: 2px 14px 9px;
        background-position: bottom 12px right;
      }
      @include SP {
        padding: 7px 22px 9px 0;
        line-height: 24px;
      }
      span {
        @include multiLineEllipsis(1);
        @include SP {
          // @include multiLineEllipsis(2);
        }
      }
    }
    .btn-more {
      @include eb();
      @include font(14, 14, 100, 400);
      width: max-content;
      min-width: 34px;
      position: relative;
      margin-top: 5px;
      &::after {
        content: "";
        width: 13px;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 50%;
        left: calc(100% + 9px);
      }
      @include screen(768px, 950px) {
        margin-right: 20px;
      }
      @include PC {
        position: absolute;
        top: 10%;
        right: 27px;
        transform: translateY(-50%);
      }
    }
    &__item {
      margin-bottom: 10px;
      display: flex;
      height: min-content !important;
      &:last-child {
        // margin-bottom: 0;
      }
      a {
        width: 100%;
        display: flex;
        height: min-content;
        @include PC {
          align-items: flex-start;
          position: relative;
          padding-right: 75px;
        }
        @include SP {
          cursor: pointer;
          display: block;
        }
      }
      @include SP {}
    }
  }
  .title-heading {
    @include ship();
    @include font(70, 85, 100, 400);
    letter-spacing: 0.22em;
    writing-mode: vertical-lr;
    .small {
      @include font(35, 44, 100, 400);
      letter-spacing: 0.26em;
      display: block;
      margin-left: 15px;
      transform: translateY(-100px);
      @include SP {
        @include font(24, 40, 100, 400);
        letter-spacing: 0.25em;
        margin-left: -1px;
        transform: translateY(-92px);
      }
      @include screen(768px, 930px) {
        font-size: 25px;
      }
      @include screen(931px, 1061px) {
        font-size: 30px;
      }
    }
    @include SP {
      @include font(35, 45, 100, 400);
      letter-spacing: 0.3em;
    }
    @include screen(768px, 930px) {
      font-size: 50px;
    }
    @include screen(931px, 1061px) {
      font-size: 60px;
    }
  }
  .block-txt {
    .subTtl {
      @include ship();
      @include font(22, 32, 100, 400);
      position: relative;
      padding-left: 24px;
      margin-bottom: 20px;
      &::before {
        content: "";
        width: 13px;
        height: 2px;
        background-color: #000;
        position: absolute;
        top: 57%;
        left: 0;
      }
      &.left {
        @include SP {
          padding-left: 0;
          padding-right: 24px;
          text-align: right;
          &::before {
            top: 47%;
            left: 94.5%;
          }
        }
      }
      @include SP {
        @include font(20, 32, 100, 400);
      }
    }
    .txt {
      @include font(14, 32, 100, 500);
      @include SP {
        letter-spacing: 0.05em;
      }
    }
  }
  .top-info {
    width: 100%;
    @include PC {
      margin: 51px auto 219px;
    }
    @include min-screen(1500px) {
      margin-bottom: 14.6vw;
    }
    @include min-screen(2110px) {
      margin-bottom: 13.379vw;
    }
    @include SP {
      flex-wrap: wrap;
      margin: -32px auto 0;
      padding: 27px 0;
    }
    &__img {
      position: relative;
      width: 100%;
      @include PC {
        padding: 10px 0 38px 0;
        width: 77.2%;
      }
      @include SP {
        order: 2;
        padding: 54px 0 19px 0;
        width: 66.4%;
        overflow: hidden;
        img {
          width: auto;
        }
      }
      .img1 {
        .img {
          @include PC {
            @include aspect-ratio(1049, 413);
          }
          @include SP {
            @include aspect-ratio(331, 220);
            max-width: 66.3vw;
          }
          @include screen(400px, 767px) {
            max-width: 74.3vw;
          }
        }
      }
    }
    &__content {
      width: 100%;
      @include min-screen(1600px) {
        height: 35.733vw;
        position: relative;
        // top: 2vw;
      }
      @include SP {
        position: relative;
        z-index: 5;
      }
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @include min-screen(1800px) {
          padding-top: 2vw;
        }
        @include SP {
          flex-direction: column;
        }

      }
      @include SP {
        order: 1;
        // position: relative;
      }
    }
    &__sub {
      margin-bottom: 20px;
      @include eb();
      @include font(13, 33, 0.15, 500);
    }
    &__ttl {
      @include ship();
      @include font(35, 80, 100, 400);
      margin-right: -30px;
      @include SP {
        @include font(22, 38, 100, 400);
        order: 0;
        margin: 0 23vw 0 auto;
        span {
          &:first-child {
            @include font(22, 23, 50, 400);
            writing-mode: vertical-rl;
            position: absolute;
            z-index: 10;
            // top: -52.7vw;
            top: -28.6%;
            left: 74.5vw;
            min-width: 70px;
            .vert {
              display: inline-block;
              transform: translate(-13px, 30px);
            }
          }
        }
      }
      @include screen(768px, 823px) {
        font-size: 28px;
        margin-right: -10px;
      }
      @include screen(824px, 938px) {
        font-size: 29px;
        margin-right: -10px;
      }
      @include max-screen(374px) {
        font-size: 20px;
      }
    }
    &__txt {
      margin: 91px 0 0 62px;
      .txt {
        @include font(15, 34, 100, 500);
        @include SP {
          @include font(14, 30, 100, 500);
        }
        @include screen(376px, 476px) {
          padding-right: 1.05em;
        }
        @include screen(768px, 892px) {
          line-height: 2em;
        }
      }
      .btn-read-default {
        margin: 56px 3px 0 auto;
        @include SP {
          margin: 36px auto;
        }
      }
      @include SP {
        margin: 62px 0;
        order: 1;
      }
      @include screen(768px, 1040px) {
        margin: 0;
      }
      @include screen(768px, 881px) {
        // margin-left: -10px;
        width: 52%;
        line-height: 2em;
      }
      @include screen(882px, 892px) {
        width: 48%;
      }
    }
  }
  .block-event {
    position: relative;
    @include PC {
      padding: 75px 0 56px;
    }
    @include SP {
      padding: 41px 0 152px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      background: #f8f8f7;
      @include PC {
        width: calc(100% - 74px);
      }
      @include screen(768px, 1200px) {
        width: calc(100% - 40px);
      }
      @include SP {
        width: 100%;
      }
    }
    .btn-read-default {
      margin: 85px 0 0 auto;
      @include SP {
        position: absolute;
        right: 50%;
        top: calc(100% - 52px);
        transform: translateX(50%);
      }
    }

    &__inner {
      position: relative;
      z-index: 2;
      padding: 0 0 0 13.55%;
      @include SP {
        padding-left: 8.6%;
      }
      @include screen(768px, 949px) {
        padding-left: 7vw;
      }
    }
    &__list {
      display: flex;
      align-items: flex-start;
      margin: 0 auto 44px;
      padding: 10px 0 0 13px;
      @include SP {
        margin-bottom: 25px;
      }
    }
    &__cat {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 19px 0 10px;
      @include SP {
        margin-top: 23px;
      }
    }
    &__ttl {
      @include font(17, 30, 0.1, bold);
      @include multiLineEllipsis(3);
      padding-right: 5px;
      margin-bottom: 6px;
    }
    &__date,
    &__address {
      @include font(14, 28, 0.1, 500);
      color: #5e6258;
      display: flex;
      align-items: flex-start;
      span {
        padding-left: 17px;
        color: #000;
        width: max-content;
        display: block;
        white-space: nowrap;
        margin-right: 15px;
      }
    }
    &__date {
      span {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.143' height='10.777' viewBox='0 0 10.143 10.777'%3E%3Cpath id='Path_364521' data-name='Path 364521' d='M14.169,3.134H12.9V2.5h-.634v.634h-3.8V2.5H7.83v.634H6.562A1.268,1.268,0,0,0,5.294,4.4v7.608a1.268,1.268,0,0,0,1.268,1.268h7.608a1.268,1.268,0,0,0,1.268-1.268V4.4a1.268,1.268,0,0,0-1.268-1.268Zm.634,8.876a.634.634,0,0,1-.634.634H6.562a.634.634,0,0,1-.634-.634V6.3H14.8Z' transform='translate(-5.294 -2.5)'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: top 45% left;
        background-size: 10px;
        @include SP {
          background-position: center left;
        }
      }
    }
    &__address {
      span {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.699' height='12.303' viewBox='0 0 8.699 12.303'%3E%3Cpath id='Path_364518' data-name='Path 364518' d='M449.355,230.8A4.385,4.385,0,0,0,445,235.18a5.184,5.184,0,0,0,.714,2.375L449.4,243.1l3.6-5.6a4.926,4.926,0,0,0,.689-2.35A4.353,4.353,0,0,0,449.355,230.8Zm.025,5.881a1.882,1.882,0,1,1,1.882-1.882A1.882,1.882,0,0,1,449.38,236.681Z' transform='translate(-445 -230.8)'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: top 45% left;
        background-size: 10px;
        white-space: nowrap;
        @include SP {
          background-position: center left;
        }
      }
    }
    &__top {
      margin: 0 auto 41px;
      padding: 0 93px 0 13px;
      display: flex;
      .title-heading {
        margin-right: 125px;
        margin-top: -127px;
        @include SP {
          margin: -65px 0 0 -4vw;
        }
        @include screen(768px, 1235px) {
          margin-right: 4vw;
        }
      }
      .txt {
        @include screen(415px, 577px) {
          padding-right: 2.4em;
        }
        @include screen(768px, 1143px) {
          padding-right: 2em;
        }
      }
      @include screen(768px, 1004px) {
        padding-right: 3em;
      }
      @include SP {
        margin-bottom: 49px;
        padding: 0 10px 0 0;
        flex-direction: column;
        .block-txt {
          margin-left: auto;
          margin-top: -46px;
          width: 83.3%;
        }
      }
    }
    .listblog_wrap {
      // overflow: visible;
      margin-left: -55px;
      padding-top: 20px;
      margin-top: -20px;
      @include SP {
        margin-left: -22px;
      }
    }
    &__item {
      max-width: 358px;
      width: 100%;
      position: relative;
      &.ended {
        .thumb {
          .img {
            &:after {
              content: "イベント完了";
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              z-index: 2;
              @include font(17, 1.4, 0.1, bold);
              color: #fff;
              background: rgba($color: #000000, $alpha: 0.7);
              // @include SP {
              //   align-items: flex-end;
              //   padding-bottom: 70px;
              // }
            }
          }
        }
      }
      a {
        opacity: 1;
        position: relative;
      }
      @include PC {
        margin-right: 20px;
      }
      @include HOVER {
        &:hover {
          .thumb {
            .img {
              transform: scale(1.1);
            }
          }
          .block-event__ttl {
            color: $color_light_brown;
          }
        }
      }
      @include SP {
        max-width: 288px;
        margin-right: 20px;
      }
      .thumb {
        overflow: hidden;
        margin-bottom: 20px;
      }
      .img {
        transition: all 0.3s ease-out;
        @include PC {
          @include aspect-ratio(358, 267);
        }
        @include SP {
          @include aspect-ratio(288, 214);
        }
      }
      .province {
        display: block;
        @include font(12, 25, 0.05, 500);
        color: #000;
        height: 28px;
        padding: 0 9px;
        background: transparent;
        border: 1px solid #000;
        border-left-width: 3px;
        margin-right: 15px;
        margin-bottom: 3px;
      }
      .cat {
        @include font(13, 34, 0.1, bold);
        color: $color_light_brown;
        height: 27px;
        border-radius: 14px;
        background: transparent;
        border: 1px solid $color_light_brown;
        padding: 5px 9px;
        display: flex;
        align-items: center;
        margin-right: 15px;
      }
    }
    &__img {
      position: absolute;
      width: 44.85vw;
      bottom: calc(100% + 114px);
      right: 0;
      img {
        @include aspect-ratio(610, 304);
      }
      @include min-screen(1500px) {
        bottom: calc(100% + 8.382vw);
        max-width: 42vw;
      }
      // @include min-screen(1790px) {
      //   bottom: calc(100% + 30px);
      // }
      @include SP {
        position: static;
        margin-left: auto;
        width: 65.4vw;
        margin-top: -74px;
      }
    }
    @include SP {
      .slider__pagination {
        left: -5px;
      }
    }
  }
  .block-works {
    padding: 0;
    &__bnr {
      img {
        @include PC {
          @include aspect-ratio(1055, 346);
        }
        @include SP {
          @include aspect-ratio(331, 220);
        }
      }
    }
    &__top {
      position: relative;
      width: 77.6%;
      margin-right: auto;
      .title-heading {
        position: absolute;
        top: 213px;
        left: calc(100% + 56px);
        @include SP {
          top: 114px;
          left: calc(100% + 27px);
          .small {
            transform: translateY(-59px);
            margin-left: -2px;
          }
        }
        @include screen(768px, 930px) {
          left: 105%;
        }
      }
      .block-txt {
        max-width: 692px;
        margin: 83px 29px 0 auto;
        padding-left: 2px;
        @include SP {
          margin: 35px 0 0 20px;
        }
        @include screen(414px, 576px) {
          .txt {
            padding-left: 2.2em;
          }
        }
        @include screen(768px, 955px) {
          margin-left: 22px;
          margin-right: 58px;
        }
        @include screen(1100px, 1300px) {
          max-width: 700px;
        }
      }
      @include SP {
        width: 68.6%;
        margin-bottom: -3px;
      }
    }
  }
  .block-contact {
    background-color: #f8f8f7;
    margin: 63px 0 71px;
    padding: 92px 0 98px;
    .en {
      @include eb();
      @include font(40, 25, 50, 400);
      @include SP {
        font-size: 30px;
      }
    }
    .jp {
      @include ship();
      @include font(16, 25, 150, 400);
      @include SP {
        font-size: 14px;
      }
    }
    .btn-more {
      @include eb();
      @include font(14, 20, 100, 400);
      width: max-content;
      position: relative;
      &::after {
        content: "";
        width: 13px;
        height: 1px;
        background-color: #000;
        position: absolute;
        top: 50%;
        left: calc(100% + 12px);
      }
    }
    &__ttl {
      .en {
        @include PC {
          margin-right: 14px;
        }
      }
      @include SP {
        text-align: center;
        .en {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
    &__cont {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      margin-top: 11px;
      .link-item-lar {
        background-color: #fff;
        margin-right: 3px;
        width: 68%;
        max-width: 727px;
        padding: 47px 54px 30px 27px;
        .link {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .img {
            width: 60%;
            max-width: 352px;
            margin-top: 3px;
            img {
              @include aspect-ratio(337, 193);
              object-fit: contain;
              @include SP {
                @include aspect-ratio(279, 159);
              }
            }
            @include SP {
              width: 100%;
              max-width: 100%;
              order: 1;
              margin-top: 7px;
            }
          }
          .cont {
            width: 50%;
            max-width: 258px;
            .ttl {
              .en {
                @include font(15, 37, 50, 400);
              }
              .jp {
                @include font(24, 64, 40, 500);
                margin: -15px 0 -3px;
                @include SP {
                  margin: -19px 0 -13px;
                  @include font(20, 64, 40, 500);
                }
              }
            }
            .txt {
              @include ship();
              @include font(15, 30, 25, 400);
              @include SP {
                @include font(14, 30, 25, 400);
              }
              @include screen(384px, 613px) {
                padding-right: 2em;
              }
              @include screen(795px, 941px) {
                padding-right: 1.1em;
              }
            }
            .btn-more {
              margin-left: auto;
              margin-top: 16px;
              @include SP {
                position: absolute;
                right: 36px;
                bottom: 23px;
              }
            }
            @include SP {
              order: 0;
              width: 100%;
              max-width: 100%;
            }
          }
          @include SP {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        @include SP {
          width: 100%;
          max-width: 100%;
          padding: 26px 14px 59px 23px;
          position: relative;
          margin-bottom: 3px;
          margin-right: 0;
        }
        @include screen(768px, 972px) {
          padding: 0 40px 0 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .link-group {
        width: 33%;
        max-width: 346px;
        &-item {
          background-color: #fff;
          height: 95px;
          &:not(:last-child) {
            margin-bottom: 3px;
          }
          .link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 22px 52px 22px 42px;
            .en {
              @include font(12, 20, 50, 400);
            }
            .jp {
              @include font(15, 20, 150, 500);
            }
            @include SP {
              padding: 22px 35px 22px 22px;
            }
          }
          @include SP {
            .btn-more {
              transform: translateY(3px);
            }
          }
          @include screen(768px, 878px) {
            padding-left: 20px;
          }
        }
        @include SP {
          width: 100%;
          max-width: 100%;
        }
      }
      @include SP {
        flex-direction: column;
        margin-top: 19px;
      }
    }
    @include SP {
      margin: 51px 0 52px;
      padding: 54px 0 59px;
    }
  }
  .block-compare {
    position: relative;
    .img-left {
      position: absolute;
      top: 156px;
      left: 0;
      width: 20.1%;
      img {
        @include aspect-ratio(273, 358);
        object-fit: contain;
      }
      @include SP {
        left: unset;
        right: 0;
        top: 32.5vw;
        width: 26%;
      }
      @include screen(670px, 767px) {
        top: 30vw;
      }
    }
    &__bnr {
      img {
        @include PC {
          @include aspect-ratio(803, 418);
        }
        @include SP {
          @include aspect-ratio(200, 105);
        }
      }
      @include SP {
        right: -17px;
        max-width: 58.4%;
        margin-left: auto;
      }
    }
    &__top {
      position: relative;
      width: 59.05%;
      margin-left: auto;
      .title-heading {
        position: absolute;
        top: 41.8%;
        right: 110.6%;
        @include SP {
          top: 11.8%;
          right: unset;
          left: 13.8%;
          .small {
            margin-left: 3px;
            transform: translateY(-67px);
          }
        }
        @include screen(768px, 1350px) {
          right: 106%;
        }
      }
      .block-txt {
        margin: 49px auto 0 6px;
        padding-left: 2px;
        .txt {
          letter-spacing: 0.15em;
          @include SP {
            line-height: 30px;
            margin-top: 32px;
          }
        }
        @include SP {
          margin: 115px auto 0 6px;
          .subTtl {
            transform: translateX(-32%);
            max-width: 335px;
          }
        }
      }
      @include SP {
        width: 100%;
        padding: 0 17px 0 15px;
      }
    }
    &__main {
      text-align: center;
      margin: 98px 0 20px;
      .inner-l {
        padding: 0;
        max-width: 100%
      }
      .ttl {
        @include font(22, 35, 150, 500);
        @include SP {
          @include font(19, 30, 150, 500);
        }
      }
      .subTtl {
        @include font(14, 20, 100, 500);
        margin: 31px 0 58px;
        @include SP {
          @include font(14, 32, 100, 500);
          text-align: left;
          margin: 20px 0;
          padding: 0 23px;
        }
        @include screen(376px, 442px) {
          padding-right: 1em;
        }
        @include screen(768px, 827px) {
          padding: 0 2em;
        }
      }
      .cont {
        &__img {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          position: relative;
          .note {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 155px;
            height: 32px;
            border: 1px solid #ff001d;
            color: #ff001d;
            @include font(14, 10, 100, 500);
            position: absolute;
            top: 23px;
            right: 14px;
            @include SP {
              top: 21px;
              right: 17px;
            }
          }
          .zoom {
            width: 41px;
            height: 41px;
            border-radius: 4px;
            background: #616162 url(../../img/common/icon/ico-zoom.svg) no-repeat center;
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .img-fscreen {
            background-color: rgba($color: #000, $alpha: 0.7);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9999999;
            align-items: center;
            width: 100vw;
            height: 100vh;
            display: none;
            .box-content {
              position: absolute;
              height: 85vh;
              left: 0;
              top: 50%;
              width: 100%;
              overflow-y: scroll;
              transform: translateY(-50%);
            }
            img {
              width: 100%;
            }
            &.show {
              display: flex;
            }
            .close {
              position: fixed;
              top: 25px;
              right: 20px;
              z-index: 99999999;
              background-color: rgba($color: #000, $alpha: 0.5);
              width: 35px;
              height: 35px;
              border-radius: 4px;
              span {
                display: block;
                width: 32px;
                height: 2px;
                margin-bottom: 5px;
                background: #fff;
                @include add_prefix(transition, 0.3s ease all);
                &:first-child {
                  transform: rotate(45deg) translate(13px, 10px);
                }
                &:last-child {
                  transform: rotate(-45deg) translate(-5px, 7px);
                }
              }
            }
          }
          @include SP {
            // background-color: #fff;
            // padding: 26px 16px 27px;
          }
        }
        &__note {
          display: flex;
          justify-content: center;
          margin: 56px auto 0;
          .txt {
            font-family: "Hiragino Sans", "ヒラギノ角ゴ", sans-serif;
            @include font(15, 26, 50, 400);
            color: #1a5fcc;
            width: max-content;
            text-align: left;
            @include screen(768px, 819px) {
              width: 57%;
              margin-right: 15px;
            }
            @include screen(820px, 984px) {
              width: 62%;
              margin-right: 15px;
            }
            @include screen(985px, 1194px) {
              width: 70%;
              margin-right: 15px;
            }
          }
          .btn-read-default {
            transform: translateY(-5px);
          }
          @include SP {
            margin: 35px auto 8px;
          }
          @include screen(768px, 984px) {
            align-items: center;
          }
        }
        @include SP {
          //padding: 35px 22px 21px;
        }
      }
      @include SP {
        margin: 75px 0 9px;
      }
    }
  }
  .block-model {
    position: relative;
    overflow: hidden;
    .inner {
      max-width: calc(100% - 188px);
      padding: 0;
      @include SP {
        padding: 0 19px;
        max-width: 100%;
      }
    }
    &__img {
      position: relative;
      overflow: hidden;
      @include PC {
        height: 308px;
      }
      @include SP {
        height: 278px;
      }
      .img {
        position: relative;
        z-index: 99;
        @include PC {
          @include aspect-ratio(2800, 1866);
          background-attachment: fixed;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 308px;
        }
        @include SP {
          @include aspect-ratio(2800, 1866);
          display: block;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -10;
        }
      }
    }
    &__txt {
      @include eb();
      @include font(14, 33, 0.15, 500);
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      z-index: 999;
      @include SP {
        font-size: 13px;
        letter-spacing: 0.15em;
        line-height: 33px;
        text-align: center;
        width: 100%;
        top: calc(50% - 17px);
      }
    }
    &__sub {
      text-align: center;
      color: #000;
      margin: 40px 0 60px;
      @include font(22, 34, 0.15, 500);
      @include SP {
        @include font(19, 30, 150, 500);
        margin: 40px 0 45px;
      }
      .title-heading {
        &__en {
          font-size: 42px;
          @include SP {
            font-size: 35px;
          }
        }
      }
    }
    &__content {
      position: relative;
      padding: 54px 0 0;
      @include SP {
        padding-top: 0;
      }
    }
    &__wrap {
      position: relative;
      .txt {
        @include eb();
        @include font(26, 1, 0.15, 500);
        position: relative;
        display: table;
        //  padding-right: 30px;
        margin: 0 0 22px;
        @include SP {
          letter-spacing: 0.135em;
          padding: 0 0 0 2px;
          margin: 0 0 28px;
          font-size: 16px;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 3px;
          left: 0;
          background: #000;
          height: 1px;
          width: 100%;
          @include SP {
            bottom: 2px;
          }
        }
      }
    }
    .btn-read-default {
      margin: 0 auto;
      @include SP {
        margin: -21px auto 0;
      }
    }
  }
  .model-box {
    display: flex;
    width: 100%;
    margin: 0 auto 74px;
    @include SP {
      flex-wrap: wrap;
      margin-bottom: 9px;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 35px;
      }
      &.fukuoka {
        // margin-bottom: 55px;
      }
    }
    &__img {
      position: relative;
      .add-tag {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 999;
        text-align: left;
        color: #fff;
        min-height: 34px;
        padding: 5px 15px;
        background: $color_light_brown;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(14, 25, 0.1, bold);
        @include SP {
          @include font(10, 0, 0);
          min-height: 22px;
          padding: 5px 5px;
        }
      }
      .img {
        //max-width: 274px;
        @include PC {
          @include aspect-ratio(504, 312);
        }
        @include SP {
          @include aspect-ratio(329, 204);
        }
      }
      .swiper-pagination {
        position: absolute;
        bottom: 5px;
        right: 0;
        text-align: right;
        padding: 0 10px;
        &.none {
          display: none;
        }
      }
      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: transparent;
        border: 1px solid #fff;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background: #fff;
          opacity: 1;
        }
        @include SP {
          width: 4px;
          height: 4px;
          margin: 0 2px;
        }
      }
    }
    &__ttl {
      @include font(15, 28, 0.12, bold);
      @include PC {
        margin: 14px 0 7px;
      }
      @include SP {
        margin: 5px 0 7px;
        line-height: 1.43em;
        font-size: 14px;
        @include multiLineEllipsis(2);
        font-weight: 500;
      }
      span {
        padding-right: 15px;
        @include SP {
          padding-right: 7px;
        }
      }
    }
    .sub-ttl {
      font-size: 15px;
      margin-bottom: 5px;
      @include SP {
        font-size: 13px;
        line-height: 20px;
      }
    }
    &__address {
      @include font(14, 26, 0.1, 500);
      color: #767676;
      padding-left: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.699' height='12.304' viewBox='0 0 8.699 12.304'%3E%3Cpath id='Path_364526' data-name='Path 364526' d='M449.355,230.8A4.385,4.385,0,0,0,445,235.18a5.184,5.184,0,0,0,.714,2.375L449.4,243.1l3.6-5.6a4.926,4.926,0,0,0,.689-2.35A4.353,4.353,0,0,0,449.355,230.8Zm.025,5.881a1.882,1.882,0,1,1,1.882-1.882A1.882,1.882,0,0,1,449.38,236.681Z' transform='translate(-445 -230.8)' fill='%23767676'/%3E%3C/svg%3E%0A");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: top 4px left;
      @include SP {
        line-height: 1.67em;
        font-size: 12px;
        margin-bottom: 5px;
        background-position: top 2px left;
        @include multiLineEllipsis(1)
      }
    }
    &__tel {
      @include font(14, 26, 0.1, 500);
      color: #767676;
      padding: 2px 0 2px 20px;
      background-image: url("data:image/svg+xml,%3Csvg id='Group_80016' data-name='Group 80016' xmlns='http://www.w3.org/2000/svg' width='11.749' height='11.75' viewBox='0 0 11.749 11.75'%3E%3Cpath id='Path_364527' data-name='Path 364527' d='M9.229,7.518l2.161,1.2a.71.71,0,0,1,.324.87,3.217,3.217,0,0,1-4.142,1.963A12.1,12.1,0,0,1,.207,4.187,3.217,3.217,0,0,1,2.17.046.711.711,0,0,1,3.041.37l1.2,2.161a.88.88,0,0,1-.113,1.027L3.119,4.693A7.4,7.4,0,0,0,7.066,8.64L8.2,7.631a.88.88,0,0,1,1.027-.113Z' transform='translate(-0.009 -0.001)' fill='%23767676' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center left;
      position: relative;
      z-index: 9;
      @include SP {
        background-position: center left;
        font-size: 13px;
        line-height: 1.5;
      }
    }
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0;
      z-index: 9;
    }
    &__item {
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease-out;

      @include PC {
        width: calc((100% - 75px) / 4);
        margin-right: 25px;
        &:last-child {
          margin: 0;
        }
      }
      @include HOVER {
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
      @include SP {
        width: 48%;
        margin-right: 0;
        margin-bottom: 18px;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }
  .block-sns {
    background-color: #f8f8f7;
    width: 94.5%;
    margin: 192px auto 0;
    padding-bottom: 90px;
    .inner {
      max-width: 1170px;
    }
    &__ttl {
      text-align: center;
      transform: translateY(-29px);
      .en {
        @include eb();
        @include font(45, 50, 50, 400);
        margin-bottom: 61px;
        @include SP {
          font-size: 30px;
          margin-bottom: 27px;
        }
      }
      .jp {
        @include font(22, 35, 150, 500);
        @include SP {
          font-size: 19px;
        }
      }
      @include SP {
        transform: translateY(-17px);
      }
    }
    &__cont {
      .js-get-instagram-post {
        display: flex;
        justify-content: space-between;
        @include SP {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 7px;
          grid-row-gap: 7px;
        }
        .item {
          width: calc((100% / 4) - 8px);
          max-width: 272px;
          display: block;
          overflow: hidden;
          @include aspect-ratio(272px, 272px);
          @include SP {
            width: 100%;
            max-width: 100%;
            @include aspect-ratio(161px, 161px);
          }
          .thumb {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            background-size: cover;
            background-position: center;
          }
        }
      }
      .ttl {
        position: relative;
        margin-bottom: 26px;
        .en {
          @include eb();
          @include font(22, 30, 100, 400);
          display: inline-block;
          margin-right: 32px;
          img {
            width: 32px;
            margin-right: 14px;
            transform: translateY(-1px);
          }
          @include SP {
            font-size: 18px;
            display: block;
            margin-bottom: 13px;
          }
          @include screen(768px, 955px) {
            display: block;
            margin-bottom: 10px;
          }
        }
        .jp {
          @include font(14, 20, 150, 500);
          @include screen(565px, 589px) {
            display: block;
            padding-right: 2.1em;
          }
          @include screen(589px, 605px) {
            white-space: nowrap;
          }
          @include SP {
            line-height: 24px;
            display: inline-block;
            width: 100%;
          }
        }
        .follow {
          @include eb();
          @include font(14, 20, 100, 400);
          position: absolute;
          top: 63%;
          right: 23px;
          transform: translateY(-50%);
          &::after {
            content: "";
            width: 13px;
            height: 2px;
            background-color: #000;
            position: absolute;
            top: 50%;
            left: calc(100% + 9px);
          }
          @include SP {
            top: 12px;
          }
        }
        @include SP {
          margin-bottom: 38px;
        }
      }
      .ig {
        margin: 36px 0 74px;
        @include SP {
          margin: 36px 0 65px;
          .gal {
            img {
              width: 100%;
            }
          }
        }
      }
      .ytube {
        // @include SP {
        //   .gal {
        //     margin: 0 -23px;
        //     overflow: scroll;
        //     picture {
        //       display: block;
        //       width: max-content;
        //       // position: relative;
        //       // left: -50%;
        //       // margin-left: -100%;
        //       // padding-left: 50%;
        //     }
        //     img {
        //       object-fit: cover;
        //       height: 167px;
        //       min-height: 167px;
        //     }
        //   }
        // }
        .gal.yt {
          @include PC {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 26.5px;
          }
          @include SP {
            width: calc(100% + 46px);
            margin-left: -23px;
            .slick-slide {
              touch-action: pan-y;
              width: 317px;
              height: 167px;
              // &.slick-current {
              //   .iframe-overlay {
              //     display: none;
              //   }
              // }
            }
            .iframe-overlay {
              @include SP {
                //display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: transparent;
              }
            }
          }
          .yt-item {
            @include SP {
              padding: 0 10px;
            }
            .video {
              @include aspect-ratio(357px, 201px);
              @include SP {
                height: 167px;
              }
              &:before {
                @include SP {
                  content: unset
                }
              }
              iframe {
                @include PC {
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                //position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
              }
            }
            @include SP {
              width: 297px;
              height: 167px;
            }
          }
        }
      }
      .btn-group {
        display: flex;
        justify-content: space-between;
        max-width: 1096px;
        margin: 67px auto 0;
        .btn-read-default {
          width: calc((100% / 3) - 10px);
          max-width: 352px;
          height: 66px;
          @include SP {
            width: 31.9%;
            margin: 0;
          }
          .txt {
            @include eb();
            @include font(20, 20, 100, 400);
            display: flex;
            align-items: center;
            @include SP {
              font-size: 18px;
              flex-direction: column;
            }
          }
          i {
            margin-right: 11px;
            display: inline-block;
            transition: all 0.3s ease-in-out;
            @include SP {
              min-height: 23px !important;
              margin-bottom: 3px;
            }
            &.fb {
              width: 31px;
              height: 31px;
              background: url(../../img/common/icon/ico-facebook-black.svg) no-repeat center;
              background-size: cover;
              @include SP {
                width: 23px;
                height: 23px;
                background-size: contain;
                background-position: center;
              }
            }
            &.tt {
              width: 26px;
              height: 30px;
              background: url(../../img/common/icon/ico-tiktok-black.svg) no-repeat center;
              background-size: cover;
              @include SP {
                width: 19px;
                height: 22px;
                background-size: contain;
                background-position: center;
              }
            }
            &.mail {
              width: 29px;
              height: 20px;
              background: url(../../img/top/ico-email.svg) center/contain no-repeat;

              @include SP {
                width: 24px;
                height: 16px;
              }
            }
            @include SP {
              margin-right: 4px;
            }
          }
          // &:not(:last-child) {
          //   margin-right: 36px;
          //   @include SP {
          //     margin-right: 12px;
          //   }
          // }
          @include SP {
            height: 62px;
          }
          @include HOVER {
            &:hover {
              a {
                color: #fff;
                i {
                  &.fb {
                    background: url(../../img/common/icon/ico-facebook.svg) no-repeat center;
                    background-size: cover;
                  }
                  &.tt {
                    background: url(../../img/common/icon/ico-tiktok.svg) no-repeat center;
                    background-size: cover;
                  }
                  &.mail {
                    background: url(../../img/top/ico-mail_w.svg) center/contain no-repeat;
                  }
                }
              }
            }
          }
        }
        @include SP {
          margin-top: 36px;
        }
      }

      @include SP {
        margin: -25px 0 0;
      }
    }
    @include SP {
      width: 100%;
      margin: 0 auto 0;
      padding-top: 74px;
      overflow: hidden;
      padding-bottom: 26px;
      position: relative;
      background-color: transparent;
      &::after {
        content: "";
        width: 100%;
        height: calc(100% - 74px);
        background-color: #f8f8f7;
        position: absolute;
        top: 74px;
        left: 0;
        z-index: -1;
      }
    }
    @include screen(768px, 1300px) {
      margin-top: 120px;
    }
  }
  .box-content {
    margin: 0 auto;
    overflow: hidden;
    &__inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 104px 0 0;
      &:first-child {}
      @include SP {
        flex-wrap: wrap;
        padding: 66px 22px 0;
      }
      +.box-content__inner {
        @include PC {
          padding-top: 0;
        }
        @include SP {
          padding: 0 0;
        }
        .box-content__wrap {
          @include PC {
            padding: 0;
          }
          @include screen(768px, 1200px) {
            padding-left: 40px;
            padding-right: 20px;
            padding-top: 4.5vw;
          }
          @include screen(768px, 1100px) {
            padding-top: 8vw;
          }
          @include SP {
            padding: 66px 22px 0;
            order: 1;
          }
        }
        .box-content__col {
          max-width: 570px;
        }
        .box-content__ttl {
          @include PC {
            margin-bottom: 16px;
          }
          @include SP {
            margin-bottom: 25px;
          }
        }
        .box-content__img {
          @include PC {
            .img1 {
              padding: 15px 51px 99px 0;
              @include screen(768px, 1200px) {
                padding-right: 0;
              }
            }
            .img2 {
              right: 0;
              left: auto;
              bottom: 0;
              width: 25.296%;
            }
          }
          @include SP {
            order: 2;
            .img1 {
              padding: 59px 0;
              margin: 0 auto 0 0;
              left: -22px;
              width: 92%;
              overflow: hidden;
            }
            .img2 {
              right: 24px;
              left: auto;
              bottom: 0;
              width: 97px;
            }
          }
        }
      }
    }
    &__col {
      max-width: 549px;
      margin: 0 auto 34px;
      @include SP {
        margin-bottom: 0;
        max-width: 100%;
      }
    }
    &__ttl {
      @include ship();
      @include font(24, 50, 0.1, 500);
      margin-bottom: 39px;
      //white-space: nowrap;
      @include screen(768px, 1200px) {
        @include font(20);
      }
      @include SP {
        line-height: 42px;
        margin-bottom: 36px;
        white-space: unset;
      }
      @include screen(376px, 677px) {
        padding-right: 1.1em;
      }
    }
    &__sub {
      margin-bottom: 34px;
      @include eb();
      @include font(13, 33, 0.15, 500);
      @include SP {
        margin-bottom: 21px;
      }
    }
    &__txt {
      @include font(15, 34, 0.1, 500);
      @include screen(768px, 1200px) {
        @include font(13, "", 0);
      }
      @include SP {
        line-height: 30px;
        padding: 0 0px 0 0;
      }
    }
    &__img {
      position: relative;
      @include PC {
        width: 50%;
      }
      @include screen(768px, 900px) {
        margin-top: 13vw;
      }
      @include screen(901px, 1200px) {
        margin-top: 4vw;
      }
      img {
        width: 100%;
      }
      .img1 {
        position: relative;
        width: 100%;
        @include PC {
          padding: 0 0 112px 36%;
        }
        @include SP {
          padding: 51px 0 38px;
          position: relative;
          right: -22px;
          width: 73%;
          margin-left: auto;
        }
      }
      .img2 {
        position: absolute;
        bottom: 79px;
        left: 10px;
        @include PC {
          width: 28.236%;
        }
        @include SP {
          width: 32.4%;
          left: -22px;
          bottom: 4px;
        }
      }
    }
    &__wrap {
      @include PC {
        width: 50%;
        padding: 37px 0 0 142px;
      }
      @include screen(768px, 1200px) {
        padding-top: 30px;
        padding-left: 20px;
      }
    }
  }
}
@include SP {
  .mainvisual,
  .top-info,
  .block-event,
  .block-works,
  .block-sns,
  .block-contact,
  .box-content,
  .block-model__content {
    background: #fff;
  }
  .footer {
    margin-top: 0;
    padding-top: 12px;
    background: #fff;
    .footer-mix {
      background: #f8f8f7;
    }
  }
}
.is-safari {
  .top {
    .block-event {
      &__date,
      &__address {
        span {
          background-position: center left;
        }
      }
    }
  }
  @include SP {
    .block-event {
      .block-txt {
        width: 84%;
      }
    }
  }
}
.is-ipad {
  .top {
    .box-content {
      &__inner {
        &:nth-child(2) {
          .box-content__wrap {
            padding-top: 4.3vw;
          }
        }
      }
    }
  }
}